<template>
  <!-- <div class="flex flex-col my-8 mt-24 sm:text-center">
    <div class="max-w-xl md:mx-auto sm:text-center lg:max-w-2xl">
      <h2
        class="mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto"
      >
        Paid access for service providers and dApps
      </h2>
    </div>
  </div> -->

  <div class="pt-16 mb-10 lg:max-w-5xl md:mb-12">
    <h2
      class="mb-6 text-3xl font-bold leading-none tracking-tight  md:text-5xl text-purple"
    >
      Paid Pricing
    </h2>
    <h2
      class="mb-6 text-xl font-bold leading-none tracking-tight text-gray-900  md:text-3xl"
    >
      Paid access for service providers and dApps
    </h2>
  </div>

  <section class="bg-white">
    <div class="container px-6 py-8 mx-auto">
      <div
        class="grid items-center gap-6  md:mx-12 lg:mx-36 sm:gap-8 sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3"
      >
        <!-- Tier-1 plan -->
        <div
          class="p-6 transition-colors duration-200 transform bg-gray-100 rounded-lg "
        >
          <p class="text-lg font-medium text-gray-800">Tier-1 plan</p>
          <h4 class="mt-2 text-4xl font-semibold text-gray-800">
            $500
            <span class="text-base font-normal text-gray-600">/ Month</span>
          </h4>

          <div class="mt-8 space-y-8">
            <div class="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5 text-purple"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                />
              </svg>

              <span class="mx-4 text-gray-700"
                >Upto
                <span class="font-bold">50 average rps</span>
              </span>
            </div>

            <div class="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5 text-purple"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                />
              </svg>

              <span class="mx-4 text-gray-700"
                >Upto <span class="font-bold">50 mbps traffic</span>
              </span>
            </div>
          </div>

          <a href="https://forms.monday.com/forms/b89a1d544375f4d7e6af3f6c9ee04478?r=use1">
            <button
              class="w-full px-4 py-2 mt-10 font-medium tracking-wide text-white capitalize transition-opacity duration-200 transform rounded-md  bg-purple hover:opacity-90 focus:outline-none focus:bg-blue-600"
            >
              Choose plan
            </button>
          </a>
        </div>
        <!-- Tier-2 plan -->
        <div
          class="p-6 transition-colors duration-200 transform bg-gray-100 rounded-lg "
        >
          <p class="text-lg font-medium text-gray-800">Tier-2 plan</p>
          <h4 class="mt-2 text-4xl font-semibold text-gray-800">
            $1000
            <span class="text-base font-normal text-gray-600">/ Month</span>
          </h4>

          <div class="mt-8 space-y-8">
            <div class="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5 text-purple"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                />
              </svg>

              <span class="mx-4 text-gray-700"
                >Upto
                <span class="font-bold">100 average rps</span>
              </span>
            </div>

            <div class="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5 text-purple"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                />
              </svg>

              <span class="mx-4 text-gray-700"
                >Upto <span class="font-bold">100 mbps traffic</span>
              </span>
            </div>
          </div>

          <a href="https://forms.monday.com/forms/19704117276dc16485dba8162bd43983?r=use1">
            <button
              class="w-full px-4 py-2 mt-10 font-medium tracking-wide text-white capitalize transition-opacity duration-200 transform rounded-md  bg-purple hover:opacity-90 focus:outline-none focus:bg-blue-600"
            >
              Choose plan
            </button>
          </a>
        </div>
        <!-- Tier-3 plan -->
        <div
          class="p-6 transition-colors duration-200 transform bg-gray-100 rounded-lg "
        >
          <p class="text-lg font-medium text-gray-800">Tier-3 plan</p>
          <h4 class="mt-2 text-4xl font-semibold text-gray-800">
            $1500
            <span class="text-base font-normal text-gray-600">/ Month</span>
          </h4>

          <div class="mt-8 space-y-8">
            <div class="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5 text-purple"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                />
              </svg>

              <span class="mx-4 text-gray-700"
                >Upto
                <span class="font-bold">150 average rps</span>
              </span>
            </div>

            <div class="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-5 h-5 text-purple"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                />
              </svg>

              <span class="mx-4 text-gray-700"
                >Upto <span class="font-bold">150 mbps traffic</span>
              </span>
            </div>
          </div>

          <a href="https://forms.monday.com/forms/9e2270ff35b7ccfe992add75dca8dab8?r=use1">
            <button
              class="w-full px-4 py-2 mt-10 font-medium tracking-wide text-white capitalize transition-opacity duration-200 transform rounded-md  bg-purple hover:opacity-90 focus:outline-none focus:bg-blue-600"
            >
              Choose plan
            </button>
          </a>
        </div>
      </div>
      <section>
        <div
          class="flex flex-col items-center p-6 mt-8 transition-colors duration-200 transform bg-gray-100 rounded-lg  md:mx-12 lg:mx-36"
        >
          <p class="mt-2 text-gray-500">All paid plans feature</p>
          <div class="flex flex-wrap">
            <div class="flex flex-col">
              <div class="flex items-center p-2 px-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 text-purple"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  />
                </svg>

                <span class="mx-4 text-gray-700"
                  ><span class="font-bold">High rate limit devnet access</span>
                </span>
              </div>
              <div class="flex items-center p-2 px-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 text-purple"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  />
                </svg>

                <span class="mx-4 text-gray-700"
                  ><span class="font-bold"
                    >Archive nodes with data back to genesis</span
                  >
                </span>
              </div>
              <div class="flex items-center p-2 px-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 text-purple"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  />
                </svg>

                <span class="mx-4 text-gray-700"
                  >Low latency, GeoDNS routing
                </span>
              </div>
            </div>
            <div class="flex flex-col">
              <div class="flex items-center p-2 px-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 text-purple"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span class="mx-4 text-gray-700"
                  >Redundant servers without single point of failure</span
                >
              </div>
              <div class="flex items-center p-2 px-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 text-purple"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span class="mx-4 text-gray-700"
                  >Multiple forms of authentication for both frontend and
                  backend</span
                >
              </div>
            </div>
            <div class="flex flex-col">
              <div class="flex items-center p-2 px-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 text-purple"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span class="mx-4 text-gray-700"
                  >24/7 monitoring and support</span
                >
              </div>
              <div class="flex items-center p-2 px-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 text-purple"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span class="mx-4 text-gray-700">Full CORS handling</span>
              </div>
              <div class="flex items-center p-2 px-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 text-purple"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  />
                </svg>

                <span class="mx-4 text-gray-700"> Gigabit speeds </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>

  <div><h2 class="mt-16 mb-8 text-center">FAQs</h2></div>
  <div class="flex flex-wrap justify-center">
    <FAQ
      v-for="faq in content.body"
      v-bind:key="faq._uid"
      v-bind:question="faq.question"
      v-bind:answer="faq.answer"
    ></FAQ>
  </div>
</template>
<script>
import FAQ from "./../components/FAQ.vue";
import { useHead } from "@vueuse/head";
export default {
  setup() {
    useHead({
      title: "Paid Pricing: Triton",
      meta: [
        {
          name: "description",
          content: "Paid access for service providers and dApps",
        },
      ],
    });
  },
  name: "Paid Pricing",

  data() {
    return {
      content: {
        body: [
          {
            "_uid": 1,
            "question": "Do I need to sign a long-term contract?",
            "answer": "No. We bill month-to-month by default.",
          },
          {
            "_uid": 2,
            "question": "Can I customise rate limits?",
            "answer":
              "Contact us and we can discuss your needs and how that may be arranged.",
          },
          {
            "_uid": 3,
            "question": "Why are there ratelimits?",
            "answer":
              "We use ratelimits to ensure that servers stay healthy, don't run behind the chain, have low response times and can continue to serve your users  - not abusers, bots or scripts - even under high loads.",
          },
          {
            "_uid": 4,
            "question": "Can I track usage from distributed JS applications?",
            "answer":
              "Yes, we can provide dashboard access to track the usage of users of your app.",
          },
          {
            "_uid": 5,
            "question":
              "Can I get daily/temporary service for my special event?",
            "answer":
              "Yes! We offer daily RPC service with a three-day minimum. Please contact us for details.",
          },
          {
            "_uid": 6,
            "question": "Do you provide uptime guarantees?",
            "answer":
              "Our aim is for the pool to always be available. To achieve this the pool has multiple levels of redundancy with both intra-region backup servers as well as the possibility to fall back on servers in different regions in case any one region would be down. We operate servers across many datacenters and several separate providers ensuring no single point of failure.",
          },
          {
            "_uid": 7,
            "question": "What if I need more traffic than tier 3 provides?",
            "answer":
              "We can combine multiple tier 3 allocations to provide for your needs or you can transition to having private dedicated servers for your app allowing for the best possible user experience.",
          },
          {
            "_uid": 8,
            "question":
              "What happens in case a server in the pool stops working?",
            "answer":
              "Requests will automatically be re-routed to another server as close to your users as possible.",
          },
          {
            "_uid": 9,
            "question": "Can I use a paid plan for my backend servers?",
            "answer":
              "Yes, you can use a paid plan for your backend servers that needs access to Solana RPC. Your servers can authenticate via token, http basic authentication or IP whitelisting.",
          },
          {
            "_uid": 10,
            "question":
              "How do I know that your nodes are up to date with the network and has required ledger data?",
            "answer":
              "Our servers keep automatic health checks guaranteeing that no server active in the pool is more than 100 slots away from the leader. We provide historical data access through archival nodes",
          },
          {
            "_uid": 11,
            "question": "What types of payment do you accept?",
            "answer":
              "We accept USDC-SPL, credit cards, and PayPal. USDC-SPL is preferred.",
          },
          {
            "_uid": 12,
            "question": "Can I change my plan at a later time?",
            "answer":
              "Yes, in case your usage changes your plan can be upgraded or downgraded based upon your needs.",
          },
          {
            "_uid": 13,
            "question":
              "What if I need more traffic/requests than any of the paid access tiers provide?",
            "answer":
              "We can configure a private pool for your needs, which uses the paid pool as a failover. Contact us to build out a custom solution.",
          },
          {
            "_uid": 14,
            "question": "What happens if my usage exceeds my plan?",
            "answer":
              "At the end of each month, we will evaluate your usage and in case it consistently exceeds your plan, we will contact you to increase your plan.",
          },
          {
            "_uid": 15,
            "question": "How do I know if my users are ratelimited?",
            "answer":
              "Your users will receive response codes 429 or 413 respectively. Rate limited users also be visible in your dashboard. If your users are experiencing rate limits, contact us and we can develop a custom rate limit for your particular use case.",
          },
        ],
      },
    };
  },

  components: {
    FAQ,
  },
};
</script>
